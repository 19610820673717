@import 'src/styles/toolkit';

.ui {
    &.modal {
        &.default-modal {
            &.income-modal {
                .modal-header {
                  margin-bottom: 30px;
                }

                .modal-subheader {
                    line-height: 25px;
                    
                    padding: 30px 0;
                    border: 1px solid map-get($jmj-grey, 400) 0;

                    font-size: 20px;
                }

                .modal-footer {
                    .button {
                        width: 200px;
                    }
                }
            }
        }
    }
}

@include respond-to(mobile) {
    .ui {
        &.modal {
            &.default-modal {
                &.income-modal {
                    .modal-subheader {
                      line-height: 20px;

                      font-size: 18px;
                    }

                    .modal-footer {
                        .button {
                            width: 100%;
                            
                            margin: 10px 0;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .ui {
        &.modal {
            &.default-modal {
                &.income-modal {
                    .modal-footer {
                        display: flex;

                        .button {
                            width: 30%;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .ui {
        &.modal {
            &.default-modal {
                &.income-modal {
                    .modal-footer {
                        display: flex;

                        .button {
                            width: 30%;
                        }
                    }
                }
            }
        }
    }
}