//Global
$offset: 14px;
$offset-half: $offset / 2;
$offset-quarter: $offset-half / 2;
$offset-double: $offset * 2;
$offset-triple: $offset * 3;

$sidebar-width: 184px;
$one-third-width: percentage(1/3);
$two-thirds-width: percentage(2/3);

$form-width: 483px;

// Colors
$jmj-grey: (
    100: rgba(235,235,235,0.5),
    200: #fafafa,
    225: #f7f7f7,
    250: #e2e2e3,
    300: #eaeaeb,
    325: #eeeeee,
    350: #e6e6e6,
    375: #d5d5d5,
    400: #c1c1c1,
    425: #999999,
    450: #9A9A9A,
    500: #727272,
    600: #696969,
    800: #333333,
    850: rgba(51,51,51,0.87),
    900: #393939,
    dimmer: rgba(0,0,0,0.7),
    1000: rgba(0,0,0,0.8)
);

$jmj-blue: (
    500: #004C97,
    600: #185EBC,
    700: #1B73E8,
    800: #1e7cff
);

$jmj-accent: (
    orange: #FF5A27,
    blue: #004C97,
    error-text: #e7bdbc
);

$jmj-grades: (
    disclosed: #ec411b,
    approved: #A03745,
    cleared: #583C6B,
    submitted: #003686,
    out: #005A7B,
    back: #007966,
    funded: #009C47
);

// Elements
$container-padding: (
    vertical: 118px,
    horizontal: 92px,
    tablet-vertical: 60px,
    tablet-horizontal: 46px,
    mobile-vertical: 30px,
    mobile-horizontal: 26px
);

$footer-height: (
    default: 180px,
    tablet: 319px,
    mobile: 366px
);

$container-width: (
    default: calc(100% - (#{map-get($container-padding, horizontal)} * 2)),
    max-width: calc(1920px - (#{map-get($container-padding, horizontal)} * 2))
);

$border-color: (
    default: map-get($jmj-grey, 300)
);

$border-radius: (
    card: 10px,
    modal: 23px,
    round: 50%
);

$font-family: (
    default: "'Lato', sans-serif",
    alternate: 'helvetica, sans-serif'
);


