@import 'src/styles/toolkit';

.add-remove-table-container {
    margin: 50px 0;

    .ui {
        &.add-remove-table {
            max-width: 75%;

            thead {
                tr {
                    th {
                        color: map-get($jmj-grey, 600);
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: none;

                    td {
                        color: map-get($jmj-grey, 450);
                    }

                    &.to-be-deleted {
                        td {
                            color: map-get($jmj-grey, 375);
                        }
                    }

                    &:not(:first-of-type) {
                        td {
                            border-top: none;
                        }
                    }
                }
            }
        }

        &.button {
            line-height: 21px;

            &.add-button {
                color: map-get($jmj-accent, orange) !important;

                .icon {
                    margin-left: 0 !important;
                }

                &:focus {
                    box-shadow: 0 0 0 1px map-get($jmj-grey, 600) !important;
                }
            }

            &.remove-button {
                .icon {
                    color: map-get($jmj-grey, 375);
                }
            }

            &.edit-button {
                position: relative;

                margin-left: $offset;
                margin-right: $offset;

                .icon,
                .edit-text {
                    transition: .5s;
                }

                .icon {
                    color: map-get($jmj-grey, 500);
                    font-size: 1.3em;
                }

                .edit-text {
                    position: absolute;
                    bottom: 0; left: 0;

                    opacity: 0;

                    color: map-get($jmj-grey, 500);
                    font-size: 15px;
                    font-weight: 700;
                }

                &:hover {
                    .icon {
                        opacity: 0;
                    }

                    .edit-text {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .income-source {
        height: 100%;
        min-height: 300px;
    }

    .add-remove-mobile-container {
        display: none;
    }
}

.ui.modal {
    &.add-remove-confirmation {
        height: auto;
    }
}

@include respond-to(tablet) {
    .add-remove-table-container {
        .ui {
            &.add-remove-table {
                display: none;
            }
        }

        .add-remove-mobile-container {
            display: block;

            .summary-section {
                padding-bottom: 4px;

                &.has-footer {
                    padding-bottom: 24px;
                }

                .details-container {
                    .detail {
                        width: percentage(1/3);
                    }
                }

                &.to-be-deleted {
                    .details-container {
                        .detail {
                            label,
                            .value {
                                color: map-get($jmj-grey, 375);
                            }
                        }
                    }
                }
            }

            .summary-section-footer {
                //@include flex-center-spaced();

                .remove-button {
                    margin-left: $offset;

                    background: map-get($jmj-accent, orange);
                }
            }
        }
    }
}

@include respond-to(tablet-landscape) {
    .add-remove-table-container {
        .ui {
            &.add-remove-table {
                display: table;
                max-width: 100%;
            }
        }

        .add-remove-mobile-container {
            display: none;
        }
    }

    .ui.tiny.modal {
        width: 45%;
    }
}

@include respond-to(mobile) {
    .add-remove-table-container {
        .add-remove-mobile-container {
            .summary-section {
                .details-container {
                    .detail {
                        width: 50%;
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .add-remove-table-container {
        .add-remove-mobile-container {
            .summary-section-footer {
                @include flex-center();
                flex-direction: column;

                button {
                    width: 100%;

                    &:first-child {
                        margin-bottom: $offset;
                    }

                    &.remove-button {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .ui.tiny.modal {
        .actions {
            display: flex;
            flex-direction: column-reverse;

            margin-bottom: 12px;

            .button {
                margin: 5px 0;
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .add-remove-table-container {
        .add-remove-mobile-container {
            .summary-section {
                .details-container {
                    .detail {
                        width: percentage(1/3);
                    }
                }
            }
        }
    }

    .ui.tiny.modal {
        width: 60%;
    }
}

@include respond-to(tiny) {
    .add-remove-table-container {
        .add-remove-mobile-container {
            .summary-section {
                .details-container {
                    .detail {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .ui.tiny.modal {
        width: 75%;

        .actions {
            display: flex;
            flex-direction: column-reverse;

            margin-bottom: 12px;

            .button {
                margin: 5px 0;
            }
        }
    }
}
