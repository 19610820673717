@import 'src/styles/toolkit';

.loan-application-page {
    padding: 0 map-get($container-padding, horizontal);

    .ui.steps.progress-bar-stepper {
        margin: 70px 0 0;
    }

    .button-submit-with-table {
        @include flex-center-spaced();
        max-width: 750px;

        margin: 40px 0;
    }

    &.add-co-applicant {
        padding: 0;
    }

    [class*="blue-button"] {
        @include resize(130px, 30px);

        padding: 0;
        background-color: map-get($jmj-blue, 700);
    }
}

.application-step-container {
    $offset-large: 32px;

    height: auto;
    overflow-x: hidden;
    overflow-y: auto;

    .question-container {
        .form-step {
            .field {
                .answer-container {
                    .icon {
                        left: 0;

                        font-size: 18px;
                    }
                }
            }
        }

        button {
            &.concierge-button {
                background: map-get($jmj-accent, orange);

                &:hover {
                    filter: brightness(85%);
                }
            }
        }
    }

    .income-form-container {
        .section-title {
            margin-bottom: 40px;
        }

        .application-form {
            .form-step {

                .additional-income {
                    display: flex;

                    margin-bottom: 100px;

                    .field {
                        &.nested {
                            display: flex;
                            flex-direction: column;

                            margin: 20px 20px 20px 0;

                            input {
                                margin-top: 15px;

                                padding-left: 0;

                                @include bottom-grey();
                            }

                            label {
                                color: map-get($jmj-grey, 450);

                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .title {
        $title-offset: 55px 0;

        margin: $title-offset 0;

        &.has-subtitle {
            margin-bottom: 0;
        }

        &.concierge {
            line-height: 46px;

            margin-top: 0;
            margin-bottom: 14px;

            color: white;
            font-size: 45px;
        }

        &.auth-and-consent {
            margin: 95px 0px;
        }

        &.reo {
            margin-bottom: 100px;
        }

        + h2,
        + h3 {
            &.subtitle {
                max-width: 700px;
                line-height: 1.2;

                margin: 20px 0 100px;

                font-family: 'Lato';
                font-weight: 500;
                font-size: 24px;

                &.coapplicant-mode {
                    max-width: 700px;

                    line-height: 1.2;
                }
            }
        }

        + h3 {
            font-size: 18px;
            font-weight: bold;
        }
    }

    .section-title {
        margin: $offset-double 0 0;
    }

    .application-form,
    .my-account-form {
        $form-width: 75%;
        $form-min-width: 720px;

        width: $form-width;

        margin: 20px 0 100px 0;

        .field {
            &.nested {
                .date-picker {
                    margin-bottom: 55px;

                    .military-expiration-date {
                        font-size: 16px;
                    }
                }

                .icon.input > input {
                    width: 150px;
                }
            }
        }

       .nested {
           &.button {
                margin: 0 0 45px $offset-large;

                font-size: 13px;
           }
        }

        .error-message {
            display: flex;
            align-items: center;

            margin: 0 35px;

            color: red;
            font-weight: bold;
            font-size: 14px;

            &.incomes {
                margin: 50px 0;

                font-size: 20px;
            }
        }

        .sub-header {
            margin-bottom: 55px
        }

        &.add-co-applicant {
            width: 75%;

            margin-top: 55px;

            .field {
                padding-bottom: 10px;

                &.co-applicant-button {
                    margin: 0;
                    padding: 0;
                }
            }

            .application-step-footer {
                padding-top: 30px;
            }
        }

        &.form-view {
            margin-top: 55px;
        }

        .demo-instructions {
            margin-bottom: 55px;
        }

        .address-container {
            .form-step {
                .field {
                    > .address-fields {
                        @include flex-center-start();
                        align-items: center;

                        margin: 0;

                        text-align: center;

                        > label {
                            font-size: 25px;
                        }

                        .residence-input,
                        .city-input,
                        .state-input {
                            align-self: flex-end;

                            margin-right: 10px;
                            padding-left: 0;
                        }

                        .residence-input {
                            input {
                                min-width: 360px;
                            }
                        }

                        .unit-input {
                            input {
                                width: 150px;
                            }
                        }

                        .city-input {
                            input {
                                @include resize(200px, 35px);

                                padding-bottom: 10px;
                            }
                        }

                        .state-input {
                            input {
                                width: 75px;

                                margin-right: 10px;
                                padding-left: 0;
                            }
                        }

                        .zip-code-input {
                            align-self: flex-end;

                            input {
                                @include resize(150px, 35px);

                                padding-bottom: 12px;
                            }
                        }
                    }
                }
            }
            .error-address-true {
                padding-bottom: 30px;

                visibility: visible;

                color: red;
                font-size: 20px;
            }

            .error-address-false {
                padding-bottom: 30px;

                visibility: hidden;

                color: red;
                font-size: 20px;
            }
        }

        .form-step {

            // CSS wildcard selector
            [class*="-button"] {
                color: white;
            }

            // CSS "starts with" wildcard selector
            [class*="add-"] {
                background: map-get($jmj-accent, orange);
            }

            [class*="remove"] {
                background: map-get($jmj-blue, 700);
            }

            label {
                font-size: 26px;

                &::before,
                &::after {
                    display: none;
                }
            }

            [class*="radio"] {
                .disabled {
                    label {
                        &:hover {
                            color: map-get($jmj-grey, 400);
                        }
                    }

                    &.checked {
                        label {
                            &:hover {
                              color: map-get($jmj-blue, 700);
                            }
                        }
                    }
                }

                label {
                    padding: 25px 25px 25px 0;

                    font-size: 22px;
                    color: map-get($jmj-grey, 400);

                    transition: .3s;

                    &:hover {
                        color: map-get($jmj-grey, 600);
                    }
                }

                &.radio-error {
                    .has-error {
                        label {
                            color: red;
                        }
                    }
                }
            }

            [class*="checked"] {
                label {
                    color: map-get($jmj-blue, 700);

                    transition: .3s;

                    &:hover {
                        color: map-get($jmj-blue, 700);
                    }
                }
            }

            .has-error {
                h2,
                .race-text,
                .ethnicity-text,
                .bankruptcy-text {
                    color: red;
                }
            }

            .add-name-button,
            .remove-name-button,
            .add-expense-button {
                margin: 0 0 60px 30px;
                padding: 10px 15px;
            }

            > .field {
                display: flex;
                flex-direction: column;
                width: 100%;

                margin-bottom: 55px;

                > label {
                    width: $form-width;
                    min-width: 100%;
                    line-height: 32px;

                    margin-bottom: 35px;

                    color: map-get($jmj-grey, 900);
                    font-size: 26px;
                    font-weight: 500;

                    &.header-label {
                        line-height: 23px;

                        font-size: 20px;

                        cursor: pointer;
                    }

                    &.address-field {
                        margin-bottom: 0;
                    }
                }

                > .time-at-residence {
                    @include flex-start();

                    > .years {
                        min-width: 0;

                        margin: 0 10px 0 0;
                    }

                    > .months {
                        min-width: 0;

                        margin: 0 10px 0 0;
                    }
                }

                .popup-icon {
                    color: map-get($jmj-blue, 700);
                }

                .label-popup-container {
                    display: flex;

                    margin-bottom: 35px;

                    .info.icon {
                        margin-left: $offset;

                        color: map-get($jmj-blue, 700);
                    }
                }

                .field-with-error-container {
                    @include flex-center-start();
                    max-width: 500px;

                    .error-message {
                        margin-top: 20px;
                    }

                    .dropdown,
                    .years,
                    .months {
                        padding-left: 0;
                    }
                }

                .birth-date,
                .past-date,
                .future-date {
                    display: flex;
                    align-items: center;
                }

                input {
                    max-width: 300px;
                    width: 300px;

                    padding-left: 0;

                    @include bottom-grey();
                }

                .small {
                    input {
                        width: 150px;
                        max-width: 150px;

                        padding-left: 0;

                        white-space: nowrap;
                    }

                    .text {
                        width: 130px;
                        overflow: hidden;

                        padding-right: 0;

                        text-overflow: ellipsis;
                    }
                }

                .selection {
                      width: 200px;

                      @include bottom-grey();

                    &.large {
                        width: 300px;

                        padding-left: 0;
                    }

                    &.small {
                        max-width: 150px;

                        padding-left: 0;
                    }

                    .text {
                        width: 100%;

                        @include ellipsis();
                    }

                    .menu {
                        .item {
                            .text {
                                white-space: normal;
                            }
                        }
                    }

                    &.error {
                        .default.text,
                        .dropdown.icon {
                            color: map-get($jmj-accent, error-text);
                        }
                    }
                }

                .search {
                    width: 150px;

                    margin-right: $offset;
                }

                .display-name {
                    margin: 30px 15px;

                    span {
                        margin: 0 10px;

                        font-size: 24px;
                    }
                }

                .co-applicant {
                    .first-name {
                        margin-right: 15px;
                    }
                }

                &.legal-name-label {
                    margin-bottom: 0;
                }

                &.borrower-name {
                    input {
                        margin: 0 15px;
                    }
                }

                &.down-payment {
                    span {
                        margin-bottom: 10px;
                    }

                    .ui {
                        margin: 10px 0;
                    }
                }

                &.nested {
                    margin-left: $offset-large;

                    > label {
                        width: calc(#{$form-width} - #{$offset-large});
                        min-width: $form-min-width - $offset-large;
                        line-height: 24px;

                        margin-bottom: 20px;

                        font-size: 20px;
                        font-weight: 500;
                    }

                    > input {
                        width: 300px;
                        max-width: 300px;

                        padding-left: 0;

                        @include bottom-grey();
                    }

                    textarea {
                        @include resize(300px, 100px);
                        max-height: 150px;
                        min-height: 50px;
                        line-height: 1.5;

                        padding: $offset;
                        border-radius: 9px;
                        border-color: map-get($jmj-grey, 400);

                        color: map-get($jmj-grey, 900);

                        resize: vertical;

                        &::-webkit-scrollbar {
                            width: 10px;
                        }

                        &::-webkit-scrollbar-track {
                            border-top-right-radius: 8px;
                        }

                        &::placeholder {
                          color: map-get($jmj-grey, 400);
                        }
                    }


                    > .small {
                        input,
                        &.selection {
                            width: 150px;
                            max-width: 150px;

                            padding-left: 0;

                            @include bottom-grey();

                            font-size: 13px;
                        }
                    }

                    .choice-container {
                        @include flex-start();
                        flex-direction: column;

                        &.military-status {
                            margin-top: 25px;

                            .choice-answers {
                                line-height: 1.5;
                            }
                        }

                        .military-error {
                            color: red;
                        }

                        .date-picker {
                            margin: 15px 35px;
                        }

                        .choice-answers {
                            @include flex-center-start();
                            position: relative;

                            padding: 5px 0 5px 30px;
                            margin: 10px 0;

                            font-size: 20px;
                            font-weight: normal;


                                &:hover {
                                    color: map-get($jmj-grey, 500);

                                    cursor: pointer;

                                    .icon {
                                        color: map-get($jmj-blue, 600);

                                        &.unselected {
                                            opacity: 1;

                                            transition: .3s ease-in-out;
                                        }
                                    }
                                }

                            .check,
                            .angle {
                                position: absolute;
                                left: 0;

                                padding: 8px 0 0 5px;

                                &.unselected {
                                    opacity: 0;
                                }

                                &.selected {
                                    opacity: 1;

                                    color: map-get($jmj-blue, 600);

                                    transition: .3s ease-in-out;
                                }
                            }

                            .info.circle {
                                width: 18px;

                                margin: 0 0 0 10px;
                            }
                        }
                    }

                    .checkbox-group {
                        @include flex-start();
                        flex-direction: column;

                        label {
                            padding: 5px 0;

                            font-size: 18px;
                        }

                        .checkbox {
                            min-width: 0;

                            margin: 0 0 20px 0;

                            font-size: 18px;

                            input {
                                width: 25px;
                                min-width: 0;

                                margin-right: $offset;
                            }

                            &.bankruptcy {
                                @include flex-start-spaced();
                                width: 450px;

                                font-size: 18px;

                                .discharge-date-container {
                                    @include flex-center-end();

                                    .bankruptcy-checked {
                                        margin-right: 15px;
                                    }

                                    .date-picker {
                                        margin: 0;
                                        border: none;

                                        input {
                                            width: 120px;

                                            margin-right: 10px;
                                            padding: 5px 0;
                                        }
                                    }
                                }
                            }
                        }

                        .date-picker {
                            margin-left: 60px;

                            @include bottom-grey();

                            font-size: 14px;
                        }

                        .remove-expense-button {
                            margin-left: $offset;

                            .icon {
                                margin: 0;
                            }
                        }

                        .bankruptcy-checked {
                            display: inline;
                        }

                        .bankruptcy-unchecked {
                            display: none;
                        }
                    }

                    &.race,
                    &.ethnicity {
                        .checkbox {
                            @include flex-center-start();

                            width: 275px;
                            min-width: unset;

                            &:hover {
                                cursor: pointer;
                            }

                            input {
                                width: 25px;
                                margin-right: 25px;

                                &:hover {
                                    cursor: pointer;
                                }
                            }

                            .input-other {
                                width: 150px;
                            }
                        }
                    }
                }
                &.dependent-count-field {
                    margin-bottom: $offset-large;
                }


                &.dependent-field {
                    @include flex-center-start();
                    flex-direction: row;
                    flex-wrap: wrap;
                    max-width: calc(100% - 40px);

                    margin-bottom: 10px;

                    label {
                        display: block;
                        width: 100%;
                        min-width: unset;
                    }

                    .remove-button {
                        margin-right: $offset-double
                    }

                    .error-message {
                        &:empty {
                            display: none;
                        }
                    }
                }
            }

            .dependent-button {
                @include flex-center-start();

                margin: 21px 0 $offset-large 37px;

                color: map-get($jmj-accent, orange) !important;

                i.icon {
                    margin-right: 28px !important;
                }
            }

            &.is-dimmed {
                opacity: 0.5;
            }

            &.form-button {
                margin-bottom: $offset-large;

                &.is-hidden {
                    display: none;
                }

                button {
                    margin-left: $form-width;
                }
            }

            .mortgage-payment {
                @include flex-center-start();

                margin-bottom: 45px;

                    .nested {
                      width: 200px;

                      margin-left: $offset-large;

                      .label-popup-container {
                          label {
                              font-size: 18px;
                          }
              
                          i {
                              margin: 0 5px 5px;
                
                              font-size: 18px;
                          }
                      }

                    > label {
                        width: calc(#{$form-width} - #{$offset-large});
                        min-width: $form-min-width - $offset-large;
                        line-height: 24px;

                        margin: 0 20px 20px 0;

                        font-size: 20px;
                    }

                    input {
                        width: 150px;
                        max-width: 150px;

                        margin-top: 30px;
                        padding-left: 0;

                        @include bottom-grey();
                    }
                }
            }
        }

        button {
            &.concierge-button {
                background: map-get($jmj-accent, orange);

                color: white;

                &:hover {
                    filter: brightness(85%);
                }

                &:focus {
                    background-color: transparent;
                    box-shadow: 0 0 0 2px map-get($jmj-accent, orange) inset;
                }
            }
        }

        .button-container {
            button {
                margin-right: 25px;

                &:hover {
                    background-color: map-get($jmj-blue, 600)
                }
            }
        }
    }

    .form-fields-container {
        &.is-offset {
            margin-left: 48px;
        }
    }

    .application-step-footer {
        @include flex-center-spaced();
        max-width: 750px;

        padding: $offset-large 0;
        border-top: 1px solid map-get($jmj-grey, 300);

        color: red;

        .error-container {
            top: unset; right: 0; left: 0;
            max-width: 700px;

            margin: 0;

            color: map-get($jmj-accent, orange);
            font-weight: 600;
            text-align: center;
        }

        &.button-right-side {
            @include flex-center-end();

            button {
                margin-left: percentage(1/5);

                &:hover {
                    background-color: map-get($jmj-blue, 600);
                }
            }
        }

        .save {
            &:hover {
                background-color: map-get($jmj-blue, 600);
            }
        }

        .button {
            font-size: 13px;


            .skip,
            &.add-co-applicant {
                background-color: map-get($jmj-blue, 700);

                color: white;
            }

            .cancel,
            &.previous {
                background-color: map-get($jmj-grey, 375);

                color: map-get($jmj-grey, 900)
            }
        }
    }

    .borrower-info {
        .form-step {

            .borrower-name {
                @include flex-center-start();

                > .dropdown {
                  margin-bottom: 5px;
                }

                .ui {
                    input {
                        margin-right: 25px;
                    }
                }
            }
        }
    }

    &.coapplicant-mode {
        .application-form {
            .form-step {
                .field {
                    label,
                    span {
                        opacity: .5;
                    }
                }
            }
        }
    }
}

// Moved modal styling from add-co-applicant.scss file. This targets both add-co-applicant & residences since both are nested under the .error-modal class
.ui {
    .error-modal {
        width: 700px;

        .content {
            @include flex-center-spaced();
            flex-direction: column;
            width: 100%;

            padding: 0 !important;

            .title {
                width: 100%;

                padding: 1rem;

                font-size: 28px;
            }

            .error-message {
                width: 100%;

                padding: 25px;
                border-bottom: 1px solid map-get($jmj-grey, 400);
                border-top: 1px solid map-get($jmj-grey, 400);

                font-size: 22px;
                text-align: center;
            }

            button {
                align-self: flex-end;

                margin: 16px;
            }
        }
    }
}

@include respond-to(tablet) {
    .loan-application-page {
        padding: 0 map-get($container-padding, tablet-horizontal);
    }

    .application-step-container {
        .title {
            margin: 45px 0;
        }

        .application-form {
            width: 100%;

            margin: 45px 0;
        }
    }

    .ui {
        .error-modal {
            &.residences-modal,
            &.add-co-applicant-modal {
                width: 50%;

                .content {
                    button {
                        margin-bottom: 16px;
                    }

                    .error-message {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@include respond-to(tablet-portrait) {
    .ui {
        .error-modal {
            &.residences-modal,
            &.add-co-applicant-modal {
                width: 70%;
            }
        }
    }
}

@include respond-to(tablet-landscape) {
    .application-step-container {
        .application-step-footer {
            min-width: 100%;
        }
    }
}

// Refactor so that both residences and add co-applicant are styling the same.
@include respond-to(mobile) {
    .loan-application-page {
        padding: 0 map-get($container-padding, mobile-horizontal);

        .application-step-container {
            .title {
                margin: 35px 0;
            }

            .application-form {
                margin: 35px 0;
            }
        }
    }

    .ui {
        .error-modal {
            &.add-co-applicant-modal,
            &.residences-modal {
                width: 85%;

                .content {
                    .title {
                        font-size: 20px;
                    }

                    .error-message {
                        font-size: 16px;
                    }

                    .button {
                        align-self: center;
                        width: 98%;

                        margin: 16px 0;
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .loan-application-page {
        .button-submit-with-table {
            @include flex-center();
            flex-direction: column;
            max-width: 100%;

            margin: 35px 0;

            button {
                width: 100%;

                margin: 0;

                &.form-previous-button {
                    order: 2;

                    margin-top: $offset;
                }
            }
        }

        .application-step-container,
        .application-form {
            .input.error {
                flex-direction: column;

                .error-message {
                    margin: 10px 0;
                }
            }

            .form-step {
                .field {
                    .field-with-error-container {
                        display: flex;
                        justify-content: unset;
                        align-items: unset;
                        flex-direction: column;

                        .input.error {
                            max-width: 100%;
                        }

                        .error-message {
                            margin: 10px 0 0;
                        }
                    }

                    .label-popup-container {
                        label {
                            line-height: 32px;
                        }

                        .info.icon {
                            margin-top: 8px;
                        }
                    }

                    &.dependent-field {
                        .dependent-field-actions {
                            width: 100%;

                            .remove-button {
                                margin-top: 8px;
                            }
                        }

                        .error-message {
                            margin: 10px 0 0 40px;
                        }
                    }
                }
            }

            .address-container {
                .form-step {
                    .field {
                        > .address-fields {
                            .residence-input,
                            .city-input,
                            .state-input,
                            .zip-code-input {
                                align-self: unset;
                            }

                            .unit-container {
                                .optional-tag {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            .application-step-footer {
                display: flex;
                flex-direction: column-reverse;

                > button {
                    width: 100%;

                    margin: 5px 0;
                }

                .message-container {
                    order: 1;

                    margin-bottom: 20px;

                    font-size: 12px;
                }
            }
        }
    }

    .ui {
        .error-modal {
            &.add-co-applicant-modal,
            &.residences-modal {
                .content {
                    .button {
                        align-self: center;
                        width: 90%;
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .ui {
        .error-modal {
            &.add-co-applicant-modal,
            &.residences-modal {
                width: 65%;

                .content {
                    .button {
                        align-self: flex-end;
                        width: 35%;

                        margin: 16px;
                    }
                }
            }

            &.residences-modal {
                width: 65%;
            }
        }
    }

    .loan-application-page {
        .application-step-container {
            .form-fields-container {
                margin-left: 0;
            }
        }
    }
}

@include respond-to(mobile-wide-landscape) {
    .loan-application-page {
        padding: 0 map-get($container-padding, mobile-horizontal);

        .application-step-container {
            .application-form {
                .application-step-footer {
                    max-width: unset;
                }
            }

            .form-fields-container {
                margin-left: 0;
            }
        }
    }
}

@include respond-to(tiny) {
    .ui {
        .error-modal {
            &.add-co-applicant-modal,
            &.residences-modal {
                width: 95%;

                .content {
                    .error-message {
                        margin: 0;

                        font-size: 14px;
                    }
                }
            }
        }

    }
}

@include respond-to(tiny-landscape) {
    .ui {
        .error-modal {
            &.add-co-applicant-modal,
            &.residences-modal {
                width: 80%;

                .content {
                    .error-message {
                        font-size: 14px;
                    }

                    .button {
                        align-self: flex-end;
                        width: 35%;

                        margin: 16px;
                    }
                }
            }
        }
    }

    .loan-application-page {
        .application-step-container {
            .application-form {
                .input.error {
                    flex-direction: column;
                }

                .error-message {
                    margin: 10px 0 0;
                }

                .form-step {
                    .field {
                        .field-with-error-container {
                            display: flex;
                            justify-content: unset;
                            align-items: unset;
                            flex-direction: column;

                            .input.error {
                                max-width: 100%;
                            }

                            .error-message {
                                margin: 10px 0 0;
                            }
                        }
                    }
                }
            }

            .form-fields-container {
                margin-left: 0;
            }
        }
    }
}
