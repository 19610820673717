.summary-section {
    width: 100%;

    margin-bottom: 20px;
    padding: 24px 26px;
    border: 1px solid map-get($jmj-grey, 250);

    .details-container {
        @include flex-start();
        flex-wrap: wrap;

        .detail {
            width: 25%;

            margin-bottom: 20px;

            label,
            .value {
                display: block;
                line-height: 16px;
            }

            label {
                margin-bottom: 4px;

                color: map-get($jmj-grey, 400);
                font-size: 12px;
                font-weight: 500;
            }

            .value {
                color: map-get($jmj-grey, 1000);
                font-size: 14px;
                font-weight: bold;
                text-transform: capitalize;
            }
        }
    }
}

.summary-section-footer {
    @include flex-center-end();

    margin-top: 26px;

    &:empty {
        display: none;
    }

    .alternate-button {
        background-color: map-get($jmj-grey, 400);

        color: white;
    }
}
