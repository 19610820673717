@import 'src/styles/toolkit';
@include respond-to(tablet) {
    .application-step-container {
        &.real-estate-owned-container {
            .application-form {
                width: 100%;

                .form-step {
                    .field {
                        label {
                            line-height: 32px;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tablet-portrait) {
    .application-step-container {
        &.real-estate-owned-container {
            .application-form {
                .form-step {
                    .address-container {
                        .form-step {
                            .field {
                                .state-input {
                                    min-width: 150px;
                                }
                            }
                        }
                    }

                    .field {
                        .choice-form {
                            height: 30vh;
                        }

                        .title {
                            font-size: 40px;
                        }
                    }
                }

                .application-step-footer {
                    width: 100%;
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .application-step-container {
        &.real-estate-owned-container {
            .application-form {
                > button {
                    width: 100%;
                }

                .button-container {
                    > button {
                        width: 100%;
                    }
                }

                .title {
                    margin: 55px 0;
                }

                .form-step {
                    .field {
                        .choice-form {
                            height: 30vh;
                        }
                        
                        .title {
                            font-size: 30px;
                            letter-spacing: -0.5px;

                            margin: 45px 0;
                        }

                        .date-picker {
                            .field {
                                width: 100%;
                            }

                            .small {
                                &.input {
                                    width: 100%;
                                }
                            }
                        }

                        .past-date {
                            flex-direction: column;
                            align-items: flex-start;

                            .date-picker {
                                width: 100%;

                                .field {
                                    width: 100%;
                                }
                            }

                            .error-message {
                                margin: 10px 0 0;
                            }
                        }

                        .selection {
                            min-width: 100%;
                        }

                        &.nested {
                            margin-left: 0;

                            > label {
                              min-width: 100%;
                            }
                        }

                        > label {
                          line-height: 32px;
                        }
                    }

                    .mortgage-payment {
                        flex-direction: column;

                        margin-bottom: 0;

                        .field {
                            &.nested {
                                display: flex;
                                flex-direction: column;
                                width: 100%;

                                margin-bottom: 45px;

                                label {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }

                    label {
                        min-width: 100%;
                    }

                    input {
                        min-width: 100%;
                    }

                    .address-container {
                        input {
                            min-width: 100%;
                        }

                        .form-step {
                            > .field {
                                margin-bottom: 0;

                                > .address-fields {
                                    flex-direction: column;
                                    align-items: stretch;

                                    .residence-input,
                                    .unit-input,
                                    .city-input,
                                    .state-input,
                                    .postal-input {
                                        margin: 0 0 30px;

                                        input {
                                            min-width: 100%;
                                        }
                                    }
                                }

                                &.bottom {
                                    margin-bottom: 45px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .application-step-container {
        &.real-estate-owned-container {
            .application-form {
                .form-step {
                    .address-container {
                        .form-step {
                            .field {
                                .state-input {
                                    min-width: 150px;
                                }

                                .address-fields {
                                  // Solves issue of Zip Code input disappearing when
                                  // .field > label min-width is set to 100%.
                                    label {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .field {
                        .title {
                            font-size: 30px;
                            letter-spacing: -0.5px;

                            margin: 45px 0;
                        }

                        label {
                            min-width: 100%;
                            line-height: 32px;
                        }
                    }
                }
            }

            .title {
                margin: 45px 0;
            }
        }
    }
}

@include respond-to(mobile-wide-landscape) {
    .application-step-container {
        &.real-estate-owned-container {
            .application-form {
                .form-step {
                    > .field {
                        .title {
                            height: 33vh;
                            line-height: 1.2;

                            margin-bottom: 0;

                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .application-step-container {
        &.real-estate-owned-container {
            .application-form {
                > button {
                    width: 100%;
                }

                .button-container {
                    > button {
                        width: 100%;
                    }
                }

                .title {
                    margin: 45px 0;
                }

                .form-step {
                    .field {
                        .title {
                            height: 15vh;
                            
                            margin: 45px 0;

                            font-size: 24px;
                        }

                        .date-picker {
                            .field {
                                width: 100%;
                            }

                            .small {
                                &.input {
                                    width: 100%;
                                }
                            }
                        }

                        .past-date {
                            flex-direction: column;
                            align-items: flex-start;

                            .date-picker {
                                width: 100%;

                                .field {
                                    width: 100%;
                                }
                            }

                            .error-message {
                                margin: 10px 0 0;
                            }
                        }

                        .selection {
                            min-width: 100%;
                        }

                        &.nested {
                            margin-left: 0;

                            > label {
                              min-width: 100%;
                            }
                        }

                        .field-with-error-container {
                            max-width: 516px;
                        }
                    }

                    .mortgage-payment {
                        flex-direction: column;

                        margin-bottom: 0;

                        .field {
                            &.nested {
                                display: flex;
                                flex-direction: column;
                                width: 100%;

                                margin-bottom: 45px;

                                label {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }

                    label {
                        min-width: 100%;
                    }

                    input {
                        min-width: 100%;
                    }

                    .address-container {
                        .form-step {
                            > .field {
                                margin-bottom: 0;

                                .search {
                                    width: unset;
                                }

                                > .address-fields {
                                    flex-direction: column;
                                    align-items: unset;

                                    .residence-input,
                                    .unit-input,
                                    .city-input,
                                    .state-input {
                                        min-width: 100%;

                                        margin-right: 0;
                                        margin-bottom: 30px;

                                        input {
                                            min-width: 100%;
                                        }
                                    }

                                    .zip-code-input {
                                        min-width: 100%;
                                    }
                                }

                                &.bottom {
                                    margin-bottom: 45px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny) {
    .application-step-container {
          &.real-estate-owned-container {
            .application-form {
                .form-step {
                    > .field {
                        .title {
                            font-size: 24px;
                        }

                        .selection {
                            width: 100%;
                        }

                        label {
                            line-height: 24px;

                            font-size: 23px;
                        }

                        .radio-group {
                            .radio {
                                label {
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
