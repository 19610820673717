@import '../../../styles/toolkit';

.popup.popup-container {
    &.section-0-popup {
        min-width: 300px;

        padding: 20px 30px;

        background-color: map-get($jmj-blue, 700);
        box-shadow: none;

        color: white;

        h2 {
            margin-bottom: $offset-half;

            color: white;
        }
    }
}

.choice-form {
    @include flex-start();
    width: 100%;

    .choice {
        all: unset;

        @include flex-center-start();
        width: 50%;

        padding: 10px 0;

        cursor: pointer;

        .angle,
        span {
            color: white;
            font-size: 42px;
        }

        .angle {
            opacity: 0;

            transition: opacity 0.25s ease-out;
        }

        span {
            line-height: 1;

            margin-left: $offset;
        }

        &:hover,
        &:focus {
            .angle {
                opacity: 1;

                transition-timing-function: ease-in;
            }
        }

        &.choice-refinance {
            padding-left: (map-get($container-padding, horizontal) / 2);
        }
    }

    &.section-0 {
        @include flex-center-spaced();

        .question-container {
            @include flex-start();

            flex-direction: column;

            .title-instructions {
                margin-bottom: 40px;

                font-size: 45px;
                font-weight: normal;
            }

            input {
                &.loan-amount {
                    margin-bottom: 35px;
                    padding-left: 0;

                    border: none;
                    border-bottom: 0.5px solid map-get($jmj-grey, 375);
                    border-radius: 0;

                    background-color: transparent;

                    color: white;
                }
            }

            .form-step {
                margin-bottom: 35px;

                font-size: 18px;

                ::placeholder {
                    color: white;
                }

                .area-container {
                    @include flex-start();

                    margin-bottom: 35px;

                    .default {
                        color: white;
                        font-size: 18px;
                    }

                    .input > input,
                    .state-input {
                        width: 150px;
                        height: 50px;

                        border: none;
                        border-bottom: 0.5px solid map-get($jmj-grey, 375);
                        border-radius: 0;

                        margin-right: 10px;
                        padding-left: 0;

                        background: none;

                        color: white;

                        input {
                            margin-right: 10px;
                            padding-left: 0;

                            color: white;
                        }

                        .divider {
                            line-height: 25px;
                        }

                        .icon {
                            padding-top: 18px;
                        }
                    }

                    .dark-theme-error {
                        ::placeholder {
                            color: map-get($jmj-accent, orange);
                            font-weight: bold;
                        }

                        .divider {
                            color: map-get($jmj-accent, orange);
                            font-weight: bold;
                        }
                    }
                }

                .address-container {
                    margin-top: 20px;

                    label {
                        font-size: 45px;
                    }

                    .input > input,
                    .state-input {
                        border: none;
                        border-bottom: 0.5px solid map-get($jmj-grey, 375);
                        border-radius: 0;

                        padding-left: 0;

                        background: none;

                        color: white;
                    }

                    .field {
                        > .address-fields {
                            @include flex-center-start();

                            margin: 0;

                            > label {
                                font-size: 25px;
                            }

                            .residence-input,
                            .city-input,
                            .state-input {
                                align-self: flex-end;

                                margin-right: 10px;
                            }

                            .residence-input {
                                input {
                                    min-width: 360px;
                                }
                            }

                            .unit-input {
                                input {
                                    width: 150px;
                                }
                            }

                            .city-input {
                                input {
                                    height: 50px;
                                    width: 200px;

                                    padding-bottom: 10px;
                                }
                            }

                            .state-input {
                                max-width: 150px;

                                white-space: nowrap;

                                input {
                                    width: 160px;
                                    padding-left: 0;
                                    color: white;
                                }

                                .default {
                                    color: white;
                                }

                                .text {
                                    width: 130px;
                                    overflow: hidden;

                                    text-overflow: ellipsis;
                                }

                                i {
                                    padding-right: 0;
                                }
                            }

                            .zip-code-input {
                                input {
                                    height: 50px;
                                    width: 150px;

                                    padding-bottom: 10px;
                                }
                            }

                            .dark-theme-error {
                                ::placeholder {
                                    color: map-get($jmj-accent, orange);
                                    font-weight: bold;
                                }

                                .divider {
                                    color: map-get($jmj-accent, orange);
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }

                .field {
                    .choice-container {
                        @include flex-start();
                        flex-direction: column;

                        padding-bottom: 20px;

                        ::placeholder {
                            color: white;
                        }

                        .purchase-location {
                            width: 300px;

                            input {
                                padding-left: 0;
                            }
                        }

                        .input > input {
                            border: none;
                            border-bottom: 0.5px solid map-get($jmj-grey, 375);
                            border-radius: 0;

                            background: none;

                            color: white;
                        }

                        .choice-answers {
                            all: unset;

                            @include flex-center-start();
                            position: relative;

                            padding: 5px 0 5px 30px;
                            margin: 10px 0 10px 0;

                            font-size: 20px;
                            font-weight: normal;

                            cursor: pointer;

                            &:hover,
                            &:focus {
                                color: white;

                                .icon {
                                    &.unselected {
                                        opacity: 1;
                                        transition: .3s ease-in-out;
                                    }
                                }
                            }

                            .check,
                            .angle {
                                position: absolute;
                                left: 0;

                                padding-left: 5px;

                                &.unselected {
                                    opacity: 0;
                                }

                                &.selected {
                                    opacity: 1;
                                    transition: .3s ease-in-out;
                                }
                            }

                            .info.circle {
                                margin: 0 0 0 10px;

                                font-size: 1em;
                            }
                        }
                    }

                    .address-container-one {
                        input {
                            width: 400px;

                            margin: 10px;
                        }
                        .unit {
                            width: 150px;
                        }
                    }

                    .address-container-two {
                        @include flex-center-spaced();

                        input {
                            width: 300px;

                            margin: 10px;
                        }

                        .postal-code {
                            width: 150px;
                        }
                    }
                }
            }

            .error {
                ::placeholder {
                    color: map-get($jmj-accent, orange);
                    font-weight: bold;
                }
            }

            .purchase-footer {
                @include flex-center-spaced();

                button {
                    background: map-get($jmj-accent, orange);

                    color: white;

                    &:hover {
                        filter: brightness(85%);
                    }

                    &:focus-within {
                        box-shadow: 0 0 0 2px white inset;
                    }
                }

                .error-container {
                    margin-left: 50px;
                }
            }
        }
    }
}

@include respond-to(tablet) {
    .choice-form {
        &.section-0 {
            .question-container {
                width: 100%;
            }
        }

        .choice {
            .angle {
                font-size: 32px
            }

            span {
                margin-left: 0;

                font-size: 32px;
            }

            &.choice-refinance {
                padding-left: 0;
            }
        }
    }
}

@include respond-to(tablet-portrait) {
    .choice-form {
        flex-direction: column;

        &.purchase-or-refinance-form {
            height: calc(100vh - 265px);
        }

        &.section-0 {
            align-items: flex-start;
            height: unset;
        }

        .choice {
            justify-content: center;
            @include resize(100%, 50%);

            padding: 0;

            span {
                text-align: center;
            }
        }
    }
}

@include respond-to(tablet-landscape) {
    .choice-form {
        .choice {
            &.choice-refinance {
                padding-left: map-get($container-padding, tablet-horizontal);
            }
        }
    }
}

@include respond-to(mobile) {
    .choice-form {
        .choice {
            span {
                font-size: 24px;
            }
        }

        &.section-0 {
            .question-container {
                width: 100%;

                .form-step {
                    .field {
                        .title-instructions {
                            margin-bottom: 20px;

                            font-size: 25px;

                            br {
                                display: none;
                            }
                        }

                        .choice-container {
                            .choice-answers {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .choice-form {
        flex-direction: column;

        .choice {
            justify-content: center;
            @include resize(100%, 50%);

            padding: 0;

            .angle {
                display: none;
            }

            span {
                text-align: center;
            }
        }

        &.purchase-or-refinance-form {
            height: calc(100vh - 156px);
        }

        &.section-0 {
            height: unset;

            .question-container {
                .form-step {
                    &.address-top-row {
                        margin-bottom: 0;
                    }

                    .field {
                        .loan-amount {
                            min-width: 100%;
                        }
                    }

                    .address-container {
                        .field {
                            > .address-fields {
                                flex-direction: column;
                                align-items: flex-start;

                                .input,
                                .state-input {
                                    margin: 13px 0;

                                    &,
                                    input {
                                        min-width: 100%;
                                    }
                                }

                                .unit-container {
                                    position: relative;
                                    width: 100%;

                                    .optional-tag {
                                        position: absolute;
                                        top: 0;

                                        margin-bottom: 15px;
                                    }
                                }
                            }
                        }
                    }
                }

                .purchase-footer {
                    flex-direction: column;
                    width: 100%;

                    .button {
                        order: 2;
                        min-width: 100%;
                    }

                    .error-container {
                        order: 1;

                        margin: 0 0 20px 0;
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .choice-form {
        .choice {
            &.choice-refinance {
                padding-left: map-get($container-padding, mobile-horizontal);
            }

            .angle {
                font-size: 22px;
            }
        }

        &.section-0 {
            .question-container {
                .purchase-footer {
                    flex-direction: row;
                    width: unset;

                    .button {
                        order: 1;
                        min-width: unset;
                    }

                    .error-container {
                        order: 2;

                        margin: 0 0 0 30px;
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-wide-landscape) {
    .choice-form {
        flex-direction: row;

        .choice {
            justify-content: flex-start;
            @include resize(50%, unset);

            padding: 10px 0;

            span {
                font-size: 24px;
                text-align: left;
            }

            &.choice-refinance {
                padding-left: map-get($container-padding, mobile-horizontal);
            }
        }

        &.purchase-or-refinance-form {
            height: unset;
        }

        &.section-0 {
            .question-container {
                width: 100%;

                .form-step {
                    .field {
                        .title-instructions {
                            margin-bottom: 20px;

                            font-size: 25px;

                            br {
                                display: none;
                            }
                        }

                        .choice-container {
                            .choice-answers {
                                font-size: 18px;
                            }
                        }
                    }
                }

                .purchase-footer {
                    flex-direction: row;
                    width: unset;

                    .button {
                        order: 1;
                        min-width: unset;
                    }

                    .error-container {
                        order: 2;

                        margin: 0 0 0 30px;
                    }
                }
            }
        }
    }
}

@include respond-to(tiny) {
    .choice-form {
        .choice {
            span {
                font-size: 18px;
            }
        }

        &.section-0 {
            .question-container {
                .form-step {
                    .field {
                        .title-instructions {
                            margin-bottom: 0;

                            font-size: 20px;
                        }

                        .loan-amount {
                            margin-top: 15px;
                        }

                        .choice-container {
                            .choice-answers {
                                margin-bottom: 0;

                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

 @include respond-to(tiny-landscape) {
    .choice-form {
        .choice {
            span {
                font-size: 18px;
            }

            &.choice-refinance {
                padding-left: map-get($container-padding, mobile-horizontal);
            }
        }

        &.purchase-or-refinance-form {
            .choice {
                .angle {
                    display: none;
                }
            }
        }
    }
}
