@import 'src/styles/toolkit';

.section-component {
    $item-offset: 10px;
    $bottom-margin: 15px;

    position: relative;
    width: 100%;

    .section-header {
        line-height: 32px;

        margin-bottom: $bottom-margin;

        color: map-get($jmj-grey, 500);
        font-size: 26px;
        font-weight: 500;
    }

    .ui.pointing.secondary.menu {
        min-height: unset;

        margin: 0 0 $bottom-margin;
        border-bottom: none;
    }

    .links-container {
        @include flex-start-spaced();

        margin: 0 0 $bottom-margin;

        &.has-tabs {
            justify-content: flex-end;

            margin-bottom: -35px;
        }

        .ui.selection.dropdown {
            width: 200px;

            margin-bottom: -1px;
            border: none;
            border-bottom: 1px solid #c1c1c1;
            border-radius: 0;

            > .text {
                width: 100%;

                @include ellipsis();
            }

            .menu {
                .item {
                    line-height: 1.2;

                    margin: 0;
                }
            }
        }
    }

    .ui.pointing.secondary.menu,
    .links-container {
        .item,
        .header-button-link {
            @include button-link-tab(0 $item-offset, $item-offset 0);

            border-bottom: none;

            box-shadow: 0 1px 0 0 map-get($jmj-grey, 400);
        }
    }
}

@include respond-to(mobile) {
    .section-component {
        .links-container {
            .ui.selection.dropdown {
                $width: 170px;

                width: $width;
                min-width: $width;
            }
        }
    }
}

@include respond-to(tiny) {
    .section-component {
        .links-container {
            &.has-dropdown {
                margin-bottom: 0;
            }

            .ui.selection.dropdown {
                margin-top: -50px;
                margin-bottom: 0;
            }
        }
    }
}
