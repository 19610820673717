@import './styles/toolkit';

.page-background {
    background: white;

    &.dark,
    &.split {
        .page-header {
            border-bottom-color: white;
        }

        .version {
            color: map-get($jmj-grey, 350);
        }
    }

    &.dark {
        background:
            linear-gradient(
                    136deg,
                    map-get($jmj-blue, 600) 0%,
                    map-get($jmj-blue, 700) 100%
            );
    }

    &.split {
        position: relative;

        background: transparent;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0; bottom: 0;
            min-height: calc(100vh + #{map-get($footer-height, default)});
            z-index: -1;

            background:
                linear-gradient(
                        136deg,
                        map-get($jmj-blue, 600) 0%,
                        map-get($jmj-blue, 700) 100%
                );
        }

        &:before {
            right: 50%; left: 0;
        }

        &:after {
            right: 0; left: 50%;
        }
    }
}

.page-container {
    flex: 1 0 auto;
    position: relative;
    width: 100vw;
    max-width: 1920px;
    min-height: 100vh;
    overflow-y: auto;

    padding: map-get($container-padding, vertical) 0;
    margin: 0 auto;

    .version {
        position: fixed;
        top: $offset; right: $offset-double;

        color: map-get($jmj-grey, 800);
        font-size: 10px;
    }
}

@include respond-to(tablet) {
    .page-container {
        padding: map-get($container-padding, tablet-vertical) 0;

        .version {
            right: map-get($container-padding, tablet-horizontal);
        }
    }

    .page-background {
        &.split {
            min-height: calc(100vh + #{map-get($footer-height, tablet)});
        }
    }
}

@include respond-to(tablet-portrait) {
    .page-background {
        &.split {
            $height-offset: 84px;

            &:before,
            &:after {
                right: 0; left: 0;
            }

            &:before {
                top: 0; bottom: calc(50% + #{$height-offset});
            }

            &:after {
                top: calc(50% - #{$height-offset}); bottom: 0;
            }
        }
    }
}

@include respond-to(mobile) {
    .page-container {
        padding: map-get($container-padding, mobile-vertical) 0;

        .version {
            right: map-get($container-padding, mobile-horizontal);
        }
    }

    .page-background {
        &.split {
            min-height: calc(100vh + #{map-get($footer-height, mobile)});
        }
    }
}

@include respond-to(mobile-portrait) {
    .page-background {
        &.split {
            $height-offset: 133.75px;

            &:before,
            &:after {
                right: 0; left: 0;
            }

            &:before {
                top: 0; bottom: calc(50% + #{$height-offset});
            }

            &:after {
                top: calc(50% - #{$height-offset}); bottom: 0;
            }
        }
    }
}

@include respond-to(mobile-wide-landscape) {
    .page-container {
        padding: map-get($container-padding, mobile-vertical) 0;

        .version {
            right: map-get($container-padding, mobile-horizontal);
        }
    }

    .page-background {
        &.split {
            &:before,
            &:after {
                top: 0; bottom: 0;
                min-height: calc(100vh + #{map-get($footer-height, mobile)});
            }

            &:before {
                right: 50%; left: 0;
            }

            &:after {
                right: 0; left: 50%;
            }
        }
    }
}
