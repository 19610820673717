.ui {
    &.blue.button {
        background-color: map-get($jmj-blue, 700);
        
        &:focus {
            background-color: transparent;
            box-shadow: 0 0 0 2px map-get($jmj-blue, 600) inset;
            
            color: map-get($jmj-blue, 600);
        }
    }

    &.orange.button {
        &:focus {
            background-color: transparent;
            box-shadow: 0 0 0 2px map-get($jmj-accent, orange) inset;
        }
    }

    &.landing-button {
        width: 146px;
        height: 36px;
        line-height: 17px;

        margin-right: 20px;
        margin-bottom: 20px;
        padding: 9px $offset 10px;
        border-radius: 4px;

        font-size: 14px;
        font-weight: bold;

        &,
        &:hover,
        &:active,
        &:focus {
            background: map-get($jmj-accent, orange);

            color: white;
        }

        &:hover {
            opacity: 0.8;
        }

        &:active {
            opacity: 0.6;
        }

        &.inverted {
            width: 180px;
            box-shadow: 0 0 0 1px white inset !important;

            &,
            &:hover,
            &:active,
            &:focus {
                background: transparent;

                color: white;
            }
        }

        &.auth-button {
            width: 141px;
        }
    }

    &.alternate-button,
    &.form-previous-button,
    &.cancel {
        width: 97px;
        height: 36px;

        padding: 0;

        &:hover {
            opacity: 0.8;
        }

        &:active {
            opacity: 0.6;
        }
        
        &:focus {
            background-color: transparent;
            box-shadow: 0 0 0 2px map-get($jmj-grey, 600) inset;
            
            color: map-get($jmj-grey, 600);
        }
    }

    &.form-save-button {
        @include resize(97px, 36px);

        padding: 0;

        &:hover {
            background-color: map-get($jmj-blue, 600);
        }

        &:active {
            opacity: 0.6;
        }
    }

    &.button {
        &.borderless {
            &,
            &:hover {
                box-shadow: none;
            }
        }

        &.no-padding {
            &,
            &:hover {
                padding: 0;
            }
        }
    }
}

@include respond-to(mobile) {
    .ui {
        &.landing-button {
            &,
            &.inverted,
            &.auth-button {
                width: 100%;
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .ui {
        &.landing-button {
            width: 146px;

            &.inverted {
                width: 180px;
            }

            &.auth-button {
                width: 141px;
            }
        }
    }
}
