.radio-group {
    .radio {
        margin-right: $offset-double;

        &.has-error {
            label {
                color: map-get($jmj-accent, orange);

                &:before,
                &:after {
                    border-color: map-get($jmj-accent, orange);
                }
            }
        }
    }
}
