html {
    scroll-behavior: smooth;
}

body {
    display: flex;
    flex-direction: column;
    line-height: 18px;

    font-family: unquote(map-get($font-family, default));
    font-size: 13px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        appearance: none;
    }

    &.no-scroll {
        overflow: hidden;
    }
}

//Prevents autofilling of forms from changing any style of input fields on webkit browsers
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    transition: all 0s 5000s !important;
}
