@import 'src/styles/toolkit';


.application-step-container {
    .application-form {
        .dependent-count-field {
            .error-message {
                margin: $offset 0 0;
            }
        }
    }
}

@include respond-to(tablet) {
    .application-step-container {
        &.borrower-info-container,
        &.coborrower-info-container {
            width: 100%;

            .application-form {
                width: 100%;

                margin: 0;

                .form-step {
                    > .field {
                        > label {
                            width: 100%;
                            min-width: 0;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .application-step-container {
        &.borrower-info-container,
        &.coborrower-info-container {
            .application-form {
                .form-step {
                    > .field {
                        .borrower-name {
                            flex-direction: column;

                            .input,
                            .selection {
                                min-width: 100%;

                                margin-bottom: 20px
                            }
                        }

                        &.dependent-field {
                            margin-left: 0;

                            .remove-button {
                                position: absolute;
                                z-index: 1;
                            }

                            input {
                                margin-left: 40px;
                            }
                        }

                        > .dropdown {
                            width: 100%;
                            max-width: unset;
                        }

                        > .date-picker {
                            .field {
                                width: 100%;

                                .input {
                                    width: 100%;
                                }

                                i {
                                    margin-right: 1px;
                                }
                            }
                        }

                        .birth-date {
                            flex-direction: column;
                            align-items: flex-start;

                            .date-picker {
                                width: 100%;

                                .field {
                                    width: 100%;
                                }
                            }

                            .error-message {
                                margin: 10px 0 0;
                            }
                        }

                        .input {
                            min-width: 100%;

                            input {
                                width: 100%;
                                max-width: unset;
                            }
                        }

                        .display-name {
                            width: 100%;

                            margin: 30px 0;

                            span {
                                width: 100%;
                                line-height: 35px;

                                margin: 0;

                                overflow-wrap: break-word;
                            }
                        }
                    }

                    .dependent-button {
                        width: 100%;

                        margin-left: 5px;

                        i.icon {
                            margin-right: 15px !important;
                        }
                    }
                }
            }
        }
    }
}
