i.icon {
    &.orange {
        color: map-get($jmj-accent, orange) !important;
    }

    &.white {
        color: white;
    }

    &.blue {
        color: map-get($jmj-blue, 700) !important;
    }

    &.grey {
        color: map-get($jmj-grey, 400) !important;
    }
}

.completed-icon {
    @include flex-center();
    @include resize(45px);

    border-radius: map-get($border-radius, round);

    background-color: map-get($jmj-blue, 700);

    i.icon {
        margin: 0;

        font-size: 19px;
    }
}
