@import 'src/styles/toolkit';

$item-blue: map-get($jmj-blue, 700);
$sub-offset: 9px;
$margin-bottom: 20px;
$item-border: 1px solid map-get($jmj-grey, 250);
$button-width: 90px;

.my-account-container {
    @include flex-start();

    margin: 32px 0 0;
    padding: 0 map-get($container-padding, horizontal);

    .section-column {
        $column-width: percentage(1/3);

        width: calc(#{$column-width} - #{$offset});

        &:first-child {
            margin-right: $offset-double;
        }

        .my-account-item {
            @include flex-center-spaced();
            width: 100%;
            min-height: 79px;

            margin-bottom: $margin-bottom;
            padding: 20px $offset-double;
            border: $item-border;

            .item-label {
                width: calc(100% - (#{$button-width} + #{$offset}));
                line-height: 30px;

                color: $item-blue;
                font-size: 24px;
                @include ellipsis();
            }

            .icon {
                margin: 0 5px 10px;

                color: map-get($jmj-blue, 700);

                &:hover {
                    cursor: pointer;
                }
            }

            .button {
                width: $button-width;

                margin: 0;

                text-transform: capitalize;

                &.blue {
                    background-color: $item-blue;
                }
            }

            .radio-container {
                @include flex-center();

                label {
                    color: map-get($jmj-blue, 700);
                    font-size: 20px;

                    &.notification-off {
                        color: map-get($jmj-grey, 400);
                    }
                }

                .toggle {
                    margin-left: $offset;
                }
            }

            .my-account-form-container {
                @include flex-start();
                flex-direction: column;

                width: 100%;

                .my-account-form {
                    width: 100%;

                    .input {
                        width: calc(50% - #{$offset-half});

                        input {
                            width: 100%;

                            padding: 2px 5px 2px 0;
                            @include bottom-grey();

                            @include ellipsis();
                            font-size: 18px;
                        }
                    }

                    .name-fields {
                        @include flex-center-start();

                        .field {
                            width: calc(50% - #{$offset-half});

                            &:first-child {
                                margin-right: $offset-double;
                            }

                            .input {
                                width: 100%;
                            }
                        }
                    }
                }

                .form-controls {
                    @include flex-center-spaced();
                    width: 100%;

                    margin-top: $offset-double;

                    .message-container {
                        max-width: 250px;

                        padding: 0 10px;

                        color: red;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@include respond-to(tablet) {
    .my-account-container {
        padding: 0 map-get($container-padding, tablet-horizontal);

        .section-column {
            .my-account-item {
                .item-label {
                    font-size: 20px;
                }

                .radio-container {
                    label {
                        font-size: 18px;
                    }
                }

                .my-account-form-container {
                    .my-account-form {
                        .input {
                            width: 75%;
                        }
                    }
                }
            }
        }
    }
}


@include respond-to(tablet-portrait) {
    .my-account-container {
        flex-wrap: wrap;

        .section-column {
            width: 100%;

            &:first-child {
                margin-right: 0;
                margin-bottom: $offset;
            }

            .my-account-item {
                padding: $offset;

                .my-account-form-container {
                    .my-account-form {
                        .input {
                            width: calc(50% - #{$offset-half});

                            input {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tablet-landscape) {
    .my-account-container {
        .section-column {
            width: calc(50% - #{$offset-half});

            &:first-child {
                margin-right: $offset;
            }
        }
    }
}

@include respond-to(mobile) {
    .my-account-container {
        flex-wrap: wrap;

        padding: 0 map-get($container-padding, mobile-horizontal);

        .section-column {
            width: 100%;

            &:first-child {
                margin-right: 0;
                margin-bottom: $offset;
            }

            .my-account-item {
                padding: $offset;

                .item-label {
                    font-size: 18px;
                }

                .my-account-form-container {
                    .my-account-form {
                        .input {
                            input {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .my-account-container {
        .section-column {
            .my-account-item {
                .my-account-form-container {
                    .my-account-form {
                        .input {
                            width: 100%;
                        }

                        .name-fields {
                            flex-wrap: wrap;

                            .field {
                                width: 100%;

                                &:first-child {
                                    margin-right: 0;
                                    margin-bottom: $offset;
                                }
                            }
                        }
                    }

                    .form-controls {
                        display: flex;
                        flex-direction: column-reverse;

                        .message-container {
                            order: 1;

                            margin-bottom: 20px;
                        }

                        .button {
                            width: 100%;

                            &.blue {
                                margin-bottom: $offset;
                            }
                        }
                    }
                }

                .delete-buttons-container {
                    button {
                        width: 75px;

                        padding: 12px 10px;

                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-wide-landscape) {
    .my-account-container {
        .section-column {
            .my-account-item {
                .my-account-form-container {
                    .my-account-form {
                        .input {
                            width: calc(50% - #{$offset-half});
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny) {
    .my-account-container {
        .section-column {
            .my-account-item {
                .label-container {
                    $radio-container-width: 110px;

                    @include flex-center-start();
                    width: calc(100% - #{$radio-container-width});

                    .item-label {
                        width: 120px;
                    }

                    .icon {
                        margin: unset;
                    }
                }
            }
        }
    }
}
