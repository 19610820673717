@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;700&display=swap');

.title {
    line-height: 54px;

    color: map-get($jmj-blue, 500);
    font-size: 45px;
    font-weight: normal;
}

.section-title {
    line-height: 36px;

    color: map-get($jmj-blue, 500);
    font-size: 32px;
    font-weight: normal;
}

.subtitle {
    line-height: 24px;

    color: map-get($jmj-blue, 500);
    font-family: unquote(map-get($font-family, alternate));
    font-size: 20px;
    font-weight: normal;
}

.text-grey {
    color: map-get($jmj-grey, 450);
}

.text-orange {
    color: map-get($jmj-accent, orange);
}

.text-blue {
    color: map-get($jmj-accent, blue);
}

.text-light-blue {
    color: map-get($jmj-blue, 700);
}

.bold {
    font-weight: bold;
}

.borrower {
    color: map-get($jmj-accent, orange);
    text-transform: capitalize;
}

.capitalized {
    text-transform: capitalize;
}

@include respond-to(tablet) {
    .title {
        letter-spacing: -0.5px;
    }
}

@include respond-to(mobile) {
    .title {
        line-height: 36px;

        font-size: 32px;
    }
}
