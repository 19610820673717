@import 'src/styles/toolkit';

.ui {
    &.modal {
        &.upload-modal {
            min-height: 420px;

            .upload-container {
                position: relative;

                .uploaded-files-container {
                    width: 100%;
                    max-height: 300px;
                    overflow-x: hidden;
                    overflow-y: auto;

                    margin-bottom: 16px;

                    .uploaded-file {
                        @include flex-center-spaced();

                        padding: 20px 34px 20px 16px;
                        border: 1px solid map-get($jmj-grey, 250);

                        background-color: map-get($jmj-grey, 225);

                        &:not(:last-child) {
                            margin-bottom: $offset-half;
                        }

                        label {
                            line-height: 15px;

                            color: map-get($jmj-blue, 700);
                            font-size: 12px;
                            font-weight: 500;
                        }

                        i.icon {
                            color: map-get($jmj-grey, 400);
                            font-size: 17px;

                            &:hover {
                                color: map-get($jmj-accent, orange);

                                cursor: pointer;
                            }
                        }
                    }
                }

                .dropzone {
                    position: relative;
                    @include flex-center();
                    @include resize(100%, 175px);

                    margin-bottom: 43px;
                    border: 1px dashed map-get($jmj-grey, 400);
                    border-radius: 17px;

                    cursor: pointer;

                    .dropzone-header {
                        @include flex-center();

                        i.icon {
                            margin-right: $offset;

                            font-size: 26px;
                        }

                        label {
                            line-height: 19px;

                            color: map-get($jmj-grey, 450);
                            font-size: 16px;
                        }
                    }

                    .drag-n-drop-ref {
                        display: inline;
                    }

                    .mobile-upload-ref {
                        display: none;
                    }
                }
            }

            &.is-dragging {
                .dropzone {
                    border-color: map-get($jmj-blue, 700);
                }
            }

            &.has-files {
                .dropzone {
                    height: 75px;
                }
            }

            .upload-modal-footer {
                button {
                    width: 120px;

                    color: white;

                    &.cancel-button {
                        margin-right: $offset-half;
                    }
                }
            }
        }
    }
}

@include respond-to(tablet-portrait) {
    .ui {
        &.modal {
            &.upload-modal {
                .upload-container {
                    .dropzone {
                        flex-direction: column;
                        justify-content: space-evenly;

                        padding: 20px;
                        margin-bottom: 20px;

                        .mobile-upload-ref {
                            display: block;
                            line-height: 19px;

                            color: map-get($jmj-grey, 450);
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile) {
    .ui {
        &.modal {
            &.upload-modal {
                &.has-files {
                    .dropzone {
                        height: 100px;
                    }
                }

                .upload-container {
                    .uploaded-files-container {
                        max-height: 200px;

                        .uploaded-file {
                            .remove {
                                padding-left: 20px;
                            }
                        }
                    }

                    .dropzone {
                        flex-direction: column;
                        justify-content: space-evenly;

                        padding: 20px;
                        margin-bottom: 20px;

                        .drag-n-drop-ref {
                            display: none;
                        }

                        .mobile-upload-ref {
                            display: inline;
                            line-height: 19px;

                            color: map-get($jmj-grey, 450);
                            font-size: 16px;
                        }
                    }
                }

                .upload-modal-footer {
                    .cancel-button {
                        margin-bottom: $offset;
                    }
                }
            }
        }
    }
}

// accounts for iPhone 5SE portrait
@include respond-to(tiny) {
    .ui {
        &.modal {
            &.upload-modal {
                .upload-container {
                    .uploaded-files-container {
                        max-height: 100px;
                    }
                }
            }
        }
    }
}
