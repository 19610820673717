@import 'src/styles/toolkit';

.page-footer {
    flex-shrink: 0;
    width: map-get($container-width, default);
    max-width: map-get($container-width, max-width);
    min-height: map-get($footer-height, default);

    margin: 0 auto;
    border-top: 1px solid map-get($jmj-grey, 300);

    color: map-get($jmj-grey, 400);

    &.dark {
        border-top: 1px solid white;

        color: white;

        .content-container {
            .links-container {
                a {
                    color: white;

                    &:hover {
                        color: map-get($jmj-grey, 400);
                    }
                }
            }

            .licensing-container {
                .info {
                    a {
                        color: white;

                        &:hover {
                            color: map-get($jmj-grey, 400);
                        }
                    }
                }
            }
        }
    }

    .content-container {
        position: relative;
        padding-bottom: 55px;

        .links-container {
            margin: 55px 0 15px 0;

            a {
                margin-right: 25px;

                color: map-get($jmj-grey, 400);

                &:hover {
                    color: map-get($jmj-grey, 500);
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .licensing-container {
            @include flex-end-spaced();

            .info{
                @include flex-end-spaced();

                span {
                    margin-right: 5px;

                    font-size: 11px;
                }

                a {
                    margin-right: 5px;

                    color: map-get($jmj-grey, 400);
                    font-size: 10.5px;

                    &:hover {
                        cursor: pointer;

                        color: map-get($jmj-grey, 500);
                    }
                }

                .address {
                    margin-right: 0;
                }
            }
        }
    }
}

@include respond-to(tablet) {
    .page-footer {
        width: unset;

        min-height: map-get($footer-height, tablet);

        margin: 0 map-get($container-padding, tablet-horizontal);

        .content-container {
            .links-container {
                @include flex-start();
            }

            .licensing-container {
                @include flex-start();
                flex-wrap: wrap;
                width: 100%;

                margin-bottom: $offset-half;

                .info {
                    @include flex-start();
                    flex-wrap: wrap;
                    width: 100%;

                    margin-bottom: $offset-half;

                    span,
                    a {
                        display: block;
                        width: 100%;

                        &.footer-divider {
                            display: none;
                        }

                        &.licensing-message {
                            margin-bottom: $offset-half;
                        }
                    }


                    &.contact-info {
                        span,
                        a {
                            display: inline-block;
                            width: unset;

                            &.footer-divider {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
}


@include respond-to(mobile) {
    .page-footer {
        min-height: map-get($footer-height, mobile);

        margin: 0 map-get($container-padding, mobile-horizontal);

        .content-container {
            padding-bottom: 30px;
        }
    }
}

@include respond-to(mobile-wide-landscape) {
    .page-footer {
        min-height: map-get($footer-height, mobile);

        margin: 0 map-get($container-padding, mobile-horizontal);

        .content-container {
            padding-bottom: 30px;
        }
    }
}
