@import 'src/styles/toolkit';

.my-loans-container {
    @include flex-start();

    padding: 0 map-get($container-padding, horizontal);

    .section-column {
        $section-offset: 10px;

        width: calc(100% - #{$section-offset});

        .section-component {
            .dash-item {
                height: auto;
                min-height: 78px;

                .dash-item-name {
                    .dash-name {
                        width: 100%;

                        @include ellipsis();
                        white-space: nowrap;
                    }
                }

                .dash-detail-container {
                    //uncomment once document link is re-implemented
                    //justify-content: space-between;
                    width: 50%;

                    .dash-detail {
                        width: auto;

                        margin-left: 10%;
                        padding: 0 2px;

                        &.is-clickable {
                            @include flex-center-end();

                            .delete.icon {
                                margin-right: 2px;
                                padding-top: 1px;
                            }

                            .angle.right {
                                align-self: flex-end;

                                margin-right: 0;
                            }
                        }

                        .icon {
                            height: 18px;

                            margin-right: 6px;

                            font-size: unset;
                        }
                    }
                }

                .my-loans-form {
                    .address-container {
                        .form-step {
                            .field {
                                > .address-fields {
                                    @include flex-center-start();
                                    align-items: center;

                                    margin: 15px 0 25px;

                                    text-align: center;

                                    > label {
                                        font-size: 25px;
                                    }

                                    .residence-input,
                                    .city-input,
                                    .state-input {
                                        margin-right: 10px;
                                        padding-left: 0;
                                    }

                                    .residence-input,
                                    .city-input,
                                    .state-input,
                                    .unit-input,
                                    .zip-code-input {
                                        input {
                                            padding-left: 0;

                                            @include bottom-grey();

                                            &:hover {
                                                cursor: revert;
                                            }
                                        }
                                    }

                                    .residence-input {
                                        input {
                                            min-width: 300px;
                                        }
                                    }

                                    .unit-input {
                                        input {
                                            width: 150px;
                                        }
                                    }

                                    .city-input {
                                        input {
                                            @include resize(200px, 35px);

                                            padding-bottom: 10px;
                                        }
                                    }

                                    .state-input {
                                        @include bottom-grey();

                                        input {
                                            width: 75px;

                                            margin-right: 10px;
                                            padding-left: 0;
                                        }
                                    }

                                    .zip-code-input {
                                        input {
                                            @include resize(150px, 35px);

                                            padding-bottom: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .form-controls {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        margin-bottom: 20px;

                        .message-container {
                            @include flex-center();

                            span {
                                color: red;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tablet) {
    .my-loans-container {
      padding: 0 map-get($container-padding, tablet-horizontal);

        .section-column {
            .section-component {
                .dash-item {
                    .dash-name {
                        max-width: 300px;
                    }

                    .dash-detail-container {
                        width: 60%;
                    }
                }
            }
        }
    }
}

@include respond-to(tablet-landscape) {
    .my-loans-container {
        .section-column {
            .section-component {
                .dash-item {
                    .dash-detail-container {
                        width: 50%;

                        .dash-detail {
                            width: auto;
                            min-width: 33%;

                            margin-left: unset;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .my-loans-container {
        margin: 5px 0;
        padding: 0 map-get($container-padding, mobile-horizontal);

        .section-column {
            width: 100%;

            .section-component {
                .dash-item {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-around;

                    padding: 10px;

                    .dash-item-name {
                        width: 100%;
                        max-width: 100%;

                        .dash-name {
                            width: 100%;

                            margin-left: 2px;

                            font-size: 20px;
                        }

                        .dash-subtext {
                            padding-left: 3px;
                        }
                    }

                    .dash-detail-container {
                        //uncomment once document link is re-implemented
                        //justify-content: space-between;
                        width: 100%;
                        max-width: unset;
                        min-width: unset;
                        min-height: 20px;

                        margin-top: 15px;

                        .dash-detail {
                            height: 20px;


                            //uncomment once document link is re-implemented
                            //margin-left: unset;
                        }
                    }

                    .my-loans-form {
                        width: 100%;

                        .address-container {
                            .form-step {
                                .field {
                                    > .address-fields {
                                        display: flex;
                                        align-items: unset;
                                        flex-direction: column;

                                        margin: unset;

                                        .residence-input,
                                        .unit-input,
                                        .city-input,
                                        .state-input,
                                        .zip-code-input {
                                            width: 100%;

                                            input {
                                                min-width: unset;

                                                margin: 10px 5px;
                                            }
                                        }

                                        .state-input {
                                            width: calc(100% - 10px);

                                            margin: 10px 5px;

                                            .icon {
                                                padding-right: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .form-controls {
                            display: flex;
                            flex-direction: column-reverse;

                            .message-container {
                                order: 1;

                                margin-bottom: 20px;
                            }

                            .button {
                                width: 100%;

                                &.blue {
                                    margin-bottom: $offset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .my-loans-container {
        .section-column {
            .section-component {
                .dash-item {
                    .dash-name {
                        max-width: 250px;
                    }

                    .dash-detail-container {
                        width: 60%;
                    }

                    .dash-detail-container {
                        .dash-detail {
                            width: unset;

                            margin-left: 5%;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny) {
    .my-loans-container {
        .section-column {
            .dash-item {
                .dash-detail-container {
                    .dash-detail {
                        label {
                            font-size: 11px;
                        }
                    }
                }

                .my-loans-form {
                    .form-controls {
                        .message-container {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .my-loans-container {
        padding: 0 map-get($container-padding, mobile-horizontal);

        .section-column {
            width: 100%;

            .section-component {
                .dash-item {
                    .dash-name {
                        max-width: 170px;
                    }

                    .dash-detail-container {
                        width: 65%;

                        .dash-detail {
                            label {
                                font-size: 11px;
                            }
                        }
                    }

                    .my-loans-form {
                        .address-container {
                            .form-step {
                                .field {
                                    > .address-fields {
                                        .state-input {
                                            .icon {
                                                padding-right: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
